'use client'; // Error components must be Client Components

import { Button } from '@/components/ui/button';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { useEffect } from 'react';

const ErrorPage = ({
	error,
	reset,
}: {
	error: Error & { digest?: string };
	reset: () => void;
}) => {
	useEffect(() => {
		// Log the error to an error reporting service
		console.error(error);
		Sentry.captureException(error);
	}, [error]);

	return (
		<section className='container mx-auto max-w-xl justify-center items-center z-10 flex-1 pt-12 flex flex-col gap-6'>
			<h2 className='text-3xl font-extrabold max-w-[300px] leading-none uppercase text-center'>
				Something went wrong!
			</h2>
			<Button
				type='button'
				onClick={
					// Attempt to recover by trying to re-render the segment
					() => {
						reset?.();
						window?.location.reload();
					}
				}
			>
				Try again
			</Button>
			<Link
				href='https://airtable.com/appmIddQx198t4f5a/shrogHQPML7S3QNWb'
				target='_blank'
				aria-label='Link to a Give Feedback form'
				className='text-center underline text-purple-600'
			>
				Report the error
			</Link>
		</section>
	);
};

export default ErrorPage;
